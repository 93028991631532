import { Component, OnDestroy, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';

import { LoaderService } from '@app/shared/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LoaderComponent implements OnInit, OnDestroy {

  @Input() spinnerVisible = false;

  spinnerRemove: boolean;
  spinnerAdd: boolean;

  private loadingSubscription: Subscription;

  constructor(
    private ref: ChangeDetectorRef,
    private loadingService: LoaderService
  ) {}

  ngOnInit() {
    this.loadingSubscription =  this.loadingService.getLoaderStatus()
    .pipe(
      distinctUntilChanged(),
      tap(
        (loaderStatus: boolean) => {
          this.spinnerVisible = loaderStatus;
          this.ref.detectChanges();
        }
      )
    )
    .subscribe();
  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }
}
