export const localStorageKeys: ILocalStorageKeys  = {
  authToken: 'access_token',
  user: 'user',
  manufacturers: 'manufacturers',
  currentManufacturer: 'currentManufacturer',
  sidenavManufacturers: 'sidenavManufacturers'
}

interface ILocalStorageKeys {
  authToken: 'access_token',
  user: 'user',
  manufacturers: 'manufacturers',
  sidenavManufacturers: 'sidenavManufacturers'
  currentManufacturer: 'currentManufacturer'
}
