<div *ngIf="spinnerVisible">
  <div class="spinner-overlay"></div>
  <div class="spinner--absolute">
    <div class="spinner" [ngClass]="{'spinner-add': spinnerAdd, 'spinner-remove': spinnerRemove}">
      <div class="rect1 bg-white"></div>
      <div class="rect2 bg-white"></div>
      <div class="rect3 bg-white"></div>
      <div class="rect4 bg-white"></div>
      <div class="rect5 bg-white"></div>
    </div>
  </div>
</div>
