import { AuthConfig } from 'angular-oauth2-oidc';

const oauthConfig: AuthConfig = {
  issuer: 'https://staging.auth.plant9.digital/auth/realms/plant9-staging',
  clientId: 'manufacturer-admin-frontend'
};

export const environment = {
  production: false,
  baseUrl: '/api',
  oauthConfig
}
