import { ToastrService } from 'ngx-toastr';
import { localStorageKeys } from '@data/localStorage';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ReplaySubject, BehaviorSubject, from, of } from 'rxjs';
import { concatMap, delay, finalize, tap } from 'rxjs/operators';

import { UserData } from '@interfaces/userData';
import { OAuthService } from 'angular-oauth2-oidc';

import { environment } from '@environments/environment';
import { LoaderService } from './loader.service';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private isLoggedInSubject = new BehaviorSubject(null);
  private userSubject: ReplaySubject<UserData | null> = new ReplaySubject();

  private jsonHeader = { 'Content-Type': 'application/json;' };

  constructor(
    private http: HttpClient,
    private readonly oauthService: OAuthService,
    private loaderService: LoaderService,
    private toastrService: ToastrService,
    private translate: TranslateService) {
  }

  private getUserFromBackend(userId: string) {
    return this.http.get<any>(`${environment.baseUrl}/users/${userId}`, this.getHeaderJwtToken());
  }

  private  getHeaderJwtToken() {
    let headers = new HttpHeaders();
    if (this.oauthService.hasValidAccessToken) {
      headers = headers.append('Authorization', `Bearer ${this.oauthService.getAccessToken()}`);
    }
    return { headers };
  }


  private updateUser(user: UserData) {
    localStorage.setItem(localStorageKeys.user, JSON.stringify(user));
  }

  setUser(user: UserData) {
    localStorage.setItem(localStorageKeys.user, JSON.stringify(user));
    this.userSubject.next(user);
  }

  getJsonTypeHeaders(): HttpHeaders {
    const headers = new HttpHeaders(this.jsonHeader);
    return headers ;
  }

  loadProfileData(): Observable<UserData> {
    return from(this.oauthService.loadUserProfile()).pipe(
      concatMap(
        userProfile => this.getUserFromBackend(userProfile.sub)
      )
    );
  }
  public updateUserData(userData: any) {
    this.setManufacturer(userData);
    this.updateUser(userData);
    this.isLoggedInSubject.next(true);
  }

  showPrivilegeErr(msg: string) {
    this.toastrService.toastrConfig.timeOut = 100000000000;
    this.toastrService.error(msg);
  }

  async initLogin() {
    this.oauthService.initLoginFlow();
  }

  public isLoggedIn(): Observable<boolean> {
    return of(this.oauthService.hasValidAccessToken());
  }

  public handleKeycloakError(): Observable<boolean> {
    this.loaderService.stop();
    return of(true).pipe(
      tap( () => {
        /** Because error comes before of loading translation loader */
        this.toastrService.error('Session expired. You will be redirect to the login page');
      }),
      delay(5000),
      finalize(
        () => {
          this.logout();
          }
      )
    )
  }

  logout(noRredirect = false) {
    this.oauthService.logOut(noRredirect);
    localStorage.clear();
  }

  private setManufacturer(user: UserData) {
    localStorage.setItem(localStorageKeys.manufacturers, JSON.stringify(user.manufacturers || []));
    localStorage.setItem(localStorageKeys.sidenavManufacturers, JSON.stringify(user.manufacturers_admin || []));
    localStorage.setItem(localStorageKeys.currentManufacturer, JSON.stringify(user.manufacturers_admin[0] || ''));
  }
}
