import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, DoBootstrap, ApplicationRef } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from '@components/loader/loader.component';
import { TranslationsLoader } from './shared/services/translation.loader';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { OAuthService, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { environment } from '@environments/environment';

export function storageFactory(): OAuthStorage {
  return localStorage;
}
@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateFactory
      }
    }),
    ReactiveFormsModule,
    ToastrModule.forRoot({ positionClass: 'toast-top-center' }),
    OAuthModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    { provide: OAuthStorage, useFactory: storageFactory }
  ],
})

export class AppModule implements DoBootstrap {

  constructor(private readonly oauthService: OAuthService) { }

  async ngDoBootstrap(app: ApplicationRef) {
    try {
      environment.oauthConfig.redirectUri = window.location.origin;
      environment.oauthConfig.logoutUrl = window.location.origin;
      environment.oauthConfig.responseType =  'code';
      environment.oauthConfig.scope = 'openid profile email offline_access';
      this.oauthService.configure(environment.oauthConfig);
      this.oauthService.tokenValidationHandler = new JwksValidationHandler();
      await this.oauthService.loadDiscoveryDocumentAndTryLogin();
      if (!this.oauthService.hasValidAccessToken() && this.oauthService.getRefreshToken()) {
        await this.oauthService.refreshToken();
      }
      this.oauthService.setupAutomaticSilentRefresh();
      app.bootstrap(AppComponent);
    } catch (error) {
      console.error('Oauth init failed', error);
    }
  }
}

export function TranslateFactory() {
  return new TranslationsLoader();
}
