import { OAuthService } from 'angular-oauth2-oidc';
import { LoaderService } from '@app/shared/services/loader.service';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { BehaviorSubject, from, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app/shared/services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  /** token got from oAuth Storage */
  // private token: string;
  private token = this.oAuthService.hasValidAccessToken() ? this.oAuthService.getAccessToken() : null;
  /** flag,  if true refresh request pending */
  private refreshTokenInProgress = false;
  /** Beahvior subject used to notifiy refresh status of token request */
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  constructor(
    private loaderService: LoaderService,
    private oAuthService: OAuthService,
    private authService: AuthService,
    private translate: TranslateService,
    private toaster: ToastrService) { }

  private interceptError({err, url}: {err: HttpErrorResponse, url?: string}) {
    if (err instanceof HttpErrorResponse) {
      this.toaster.toastrConfig.preventDuplicates = true;
      try {
        if(url && url.indexOf('auth.plant9.digital') > -1) {
          this.authService.handleKeycloakError().subscribe();
          return;
        }
        if(err.error.statusCode === 422) {
          this.toaster.error(this.translate.instant('ERROR.INVALID_CREDENTIALS'));
          this.loaderService.stop();
          return;
        }
        if (err.error && err.error.error.message) {
          this.toaster.error(this.formatErrorMessage(err).error.error.message);
        } else {
          this.toaster.error(err.message);
        }
      } catch (e) {
        this.toaster.error('An error occurred', '');
      }
    }

    this.loaderService.stop();
  }

  private formatErrorMessage(error: HttpErrorResponse): HttpErrorResponse {
    if (error.error.error?.key) {
      error.error.error.message = this.translate.instant(error.error.error.key);
    }
    return error;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        /** If I have access_token use normal error handler */
        if(this.oAuthService.getRefreshToken()) {
          if(request.url.indexOf('auth.plant9.digital') > -1) {
            this.authService.handleKeycloakError().subscribe();
          }
          this.interceptError({err: error, url: request?.url || '' });
          return throwError(error);
        }
        /** If access_token is valid, or error status is other than 401 handle errors normally using dedicated service */
        if (!this.oAuthService.getRefreshToken()) {
          this.interceptError({err: error});
          return throwError(error);
        } else {
          if (this.refreshTokenInProgress) {
            /**  If refreshTokenInProgress is true, wait until refreshTokenSubject has a non-null value */
            /** which means the new token is ready and we can retry the request again */
            return this.refreshTokenSubject.pipe(
              filter(result => result !== null),
              take(1),
              switchMap(() => next.handle(this.makeFixedRequest(request)))
            );
          } else {
            this.refreshTokenInProgress = true;

            /** Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved  */
            this.refreshTokenSubject.next(null);
            return this.refreshTokenAndRetryReq(next, request);
          }
        }
      }
    ));
  }

  /**
   * @description Makes refresh call and  returns call with updated token
   *
   * @param request current request
   * @param next http handler
   */

  private refreshTokenAndRetryReq(next: HttpHandler, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    return from(this.oAuthService.refreshToken()).pipe(
      switchMap((response) => {
        this.token = response.access_token;
        this.refreshTokenSubject.next(true);
        return next.handle(this.makeFixedRequest(request));
      }),
      /** When the call to refreshToken completes resets the refreshTokenInProgress to false */
      finalize(() => this.refreshTokenInProgress = false),
      catchError( (error) => {
        if(request.url.indexOf('auth.plant9.digital') > -1) {
          this.authService.handleKeycloakError().subscribe();
        }
        this.interceptError(error);
        return throwError(error);
      })
    );
  }

  /**
   * @description return request with updated token
   *
   * @param request original request
   * @param next http handler
   * @param token token
   */

  private makeFixedRequest(request: HttpRequest<any>): HttpRequest<any> {
    /** If token is null return request */
    if (!this.token) {
      return request;
    }
    return request.clone({ headers: request.headers.set('Authorization', `Bearer ${this.token}`) });
  }

}
