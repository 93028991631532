import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class LoaderService {
  private loader$ = new BehaviorSubject<boolean>(false);
  loaderObs$: Observable<boolean>;

  loadingWaitingTime = 400;

  getLoaderStatus(): Observable<boolean>   {
    this.loaderObs$ = this.loader$.asObservable();
    return this.loaderObs$;
  }

  start() {
    this.loader$.next(true);
  }

  stop() {
    this.loader$.next(false);
  }

}
