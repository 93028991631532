import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import en from '@assets/i18n/en.json';

export class TranslationsLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    switch (lang) {
      case 'en':
        return of(en);
      default:
        return of(en);
    }
  }
}
